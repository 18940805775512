<template>
  <div style="align-center">
    <b-row cols="8" style="margin-bottom:0px">
      <b-col lg="3" sm="3" md="3" v-for="(item, idx) in activeList" :key="idx">
        <b-link :to="item.to">
          <b-card class="text-center">
            <feather-icon
                size="36"
                :icon="item.icon"
                :class="`text-${item.iconColor} my-2`"
            />

            <div :class="`mb-25 font-weight-bolder text-primary`">
              {{ item.text }}
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar, BRow, BCol, BCard, BCardText, BLink,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
  },
  created() {
    this.list.forEach(r => {
      this.activeList.push(r)
    })
  },
  data() {
    return {
      activeList: [],
      list: [
        {
          icon: 'LayersIcon',
          text: '个人信息政策',
          to: '/policy',
          iconColor: 'primary',
          bgColor: 'warning',
          textColor: 'white',
        },
        {
          icon: 'LayersIcon',
          text: '个人信息组织',
          to: '/policy/organization',
          iconColor: 'primary',
          bgColor: 'warning',
          textColor: 'white',
        },
        {
          icon: 'LayersIcon',
          text: '个人信息声明',
          to: '/statement',
          iconColor: 'primary',
          bgColor: 'warning',
          textColor: 'white',
        },
        {
          icon: 'LayersIcon',
          text: '制度流程文档',
          to: '/regime',
          iconColor: 'primary',
          bgColor: 'warning',
          textColor: 'white',
        },
        // {
        //   icon: 'EyeIcon',
        //   text: '隐私政策',
        //   to: '/enterprise/policy',
        //   iconColor: 'success',
        //   bgColor: 'info',
        //   textColor: 'white',
        // },
        {
          icon: 'PackageIcon',
          text: 'IT资产管理',
          to: '/asset',
          iconColor: 'success',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'ClipboardIcon',
          text: '个人信息词典',
          to: '/infoDict',
          iconColor: 'success',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'DatabaseIcon',
          text: '个人信息资产',
          to: '/infoAssest',
          iconColor: 'success',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'CpuIcon',
          text: '个人信息映射',
          to: '/dataActivity',
          iconColor: 'success',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'CheckSquareIcon',
          text: '快速评估',
          to: '/compliance-list',
          iconColor: 'danger',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'CheckSquareIcon',
          text: '认证评估',
          to: '/compliance-analysis',
          iconColor: 'danger',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'CheckSquareIcon',
          text: 'PIA评估',
          to: '/pia',
          iconColor: 'danger',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'LifeBuoyIcon',
          text: '个人信息图谱',
          to: '/dataAtlas',
          iconColor: 'danger',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'BarChart2Icon',
          text: '风险态势管理',
          to: '/risk-stat',
          iconColor: 'primary',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'ClipboardIcon',
          text: 'Cookie管理',
          to: '/cookies-list',
          iconColor: 'primary',
          bgColor: 'danger',
          textColor: 'white',
        },
        {
          icon: 'PieChartIcon',
          text: '同意偏好统计',
          to: '/cpm-statistic',
          iconColor: 'primary',
          bgColor: 'danger',
          textColor: 'white',
        },
        {
          icon: 'MapIcon',
          text: '业务管理',
          to: '/product',
          iconColor: 'primary',
          bgColor: 'danger',
          textColor: 'white',
        },
        {
          icon: 'SettingsIcon',
          text: '企业信息',
          to: '/enterprise',
          iconColor: 'warning',
          bgColor: 'danger',
          textColor: 'white',
        },
        {
          icon: 'UserIcon',
          text: '用户管理',
          to: '/settings/user',
          iconColor: 'warning',
          bgColor: 'danger',
          textColor: 'white',
        },
        {
          icon: 'UsersIcon',
          text: '部门管理',
          to: '/settings/organization',
          iconColor: 'warning',
          bgColor: 'danger',
          textColor: 'white',
        },
        {
          icon: 'ArchiveIcon',
          text: '第三方管理',
          to: '/thridParty',
          iconColor: 'warning',
          bgColor: 'danger',
          textColor: 'white',
        },
      ],
    }
  },
}
</script>

<style>
</style>
